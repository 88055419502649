import base from './base';
const baseUrl = 'https://api.editoramunera.com.br';
const apiUrl = `${baseUrl}/api`;
const clarityKey = 'lfk1zv8mqr';

export default {
  ...base,
  baseUrl: baseUrl,
  apiUrl: apiUrl,
  searchAPIUrl: `https://search.editoramunera.com.br`,
  AppKey: 'AA52sG8T0QMj56DIGQXnhzbvLxmVThQqobiAJfRNsZYR',
  recaptchaKey: '6Le-l6IZAAAAAGaH2BCzGy6b80TVaASx_XMdnWx4',
  websocketUrl: 'wss://api.editoramunera.com.br:44443',
  clarityKey
};
