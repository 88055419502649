import React from 'react';

import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

export const SearchSkeleton = () => {
  return (
    <>
      {Array.from(new Array(8)).map((_, index) => (
        <ListItem
          key={index}
          style={{
            marginBottom: '14px'
          }}
          marginBottom={14}
        >
          <ListItemAvatar>
            <Skeleton variant="circle" width={'48px'} height={'48px'} />
          </ListItemAvatar>
          <ListItemText primary={<Skeleton width="80%" height="18px" />} />
        </ListItem>
      ))}
    </>
  );
};
