import base from './base';
const baseUrl = 'https://beta.saberes.edicoesipdh.com.br';
const apiUrl = `${baseUrl}/api`;
const clarityKey = 'lfk1ejdvry';

export default {
  ...base,
  baseUrl: baseUrl,
  apiUrl: apiUrl,
  searchAPIUrl: `https://search.saberes.edicoesipdh.com.br`,
  AppKey: 'MbvjqAQ8K6Cl8uWTIbkzY3yuIPjywSmekhHXz3Vpqr0',
  recaptchaKey: '6Le-l6IZAAAAAGaH2BCzGy6b80TVaASx_XMdnWx4',
  websocketUrl: 'wss://beta.saberes.edicoesipdh.com.br:44443',
  clarityKey
};
