/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';

import MemberItem from './MemberItem';
import { useStyles } from './styles';
import { Box, Checkbox, Typography, useTheme } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Skeleton } from '@material-ui/lab';
import debounce from 'debounce';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

const roles = {
  member: i18n.t('Student'),
  tutor: i18n.t('Tutor'),
  teacher: i18n.t('Teacher'),
  environment_admin: i18n.t('Admin')
};

const usersPerPage = 20;

const Members = ({ structureID, getMembers, updateRole, canManage }) => {
  const theme = useTheme();
  const classes = useStyles({ theme: theme });
  const { enqueueSnackbar } = useSnackbar();

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [rolesSelected, setRolesSelected] = useState([
    'member',
    'tutor',
    'teacher',
    'environment_admin'
  ]);

  const inputRef = useRef(null);

  const handleChangeCheckbox = (label) => {
    if (rolesSelected.includes(label)) {
      setRolesSelected((prev) => prev.filter((e) => e != label));
    } else {
      setRolesSelected((prev) => [...prev, label]);
    }
  };

  const getUsers = async (query = searchQuery) => {
    if (loading || !hasMore) return;
    try {
      setLoading(true);
      const response = await getMembers(structureID, query, page, usersPerPage);
      const hasMoreItens = response.length >= usersPerPage;
      setHasMore(hasMoreItens);
      setPage((prev) => (hasMoreItens ? prev + 1 : prev));
      setUsers((prev) => [...prev, ...response]);
    } catch (error) {
      enqueueSnackbar(
        'Não foi possível carregar a lista de membros, se o erro persistir por favor entre em contato com a equipe de suporte.',
        { variant: 'error' }
      );
    } finally {
      setLoading(false);
      if (inputRef.current) inputRef.current.focus();
    }
  };

  const getMoreItems = (e) => {
    if (loading || !hasMore) return;

    const bottom =
      e.target.scrollHeight <= e.target.scrollTop + e.target.offsetHeight + 100;

    if (bottom && users.length >= usersPerPage) {
      getUsers();
    }
  };

  const handleSearch = (event) => {
    if (debouncedSearch) debouncedSearch.clear();
    debouncedSearch(event.target.value);
  };

  const debouncedSearch = debounce((searchString) => {
    setPage(1);
    setUsers([]);
    setHasMore(true);
    setSearchQuery(searchString);
  }, 500);

  useEffect(() => {
    getUsers();
  }, [searchQuery]);

  const renderCheckbox = (label) => {
    return (
      <Box className={classes.checkboxElement}>
        <Checkbox
          checked={rolesSelected.includes(label)}
          onChange={() => handleChangeCheckbox(label)}
          classes={{ root: classes.checkbox }}
        />
        <Text variant="text" color="gray">
          {roles[label]}
        </Text>
      </Box>
    );
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.searchBarContainer}>
        <input
          className={classes.searchBar}
          placeholder={i18n.t('searchMembroPlaceholder')}
          ref={inputRef}
          disabled={loading}
          onChange={handleSearch}
        />
        <SearchIcon
          style={{
            color: theme.palette.primary.main,
            width: 24,
            height: 24,
            marginRight: 8
          }}
        />
      </Box>
      <Box className={classes.checkboxContainer}>
        {renderCheckbox('member')}
        {renderCheckbox('tutor')}
        {renderCheckbox('teacher')}
        {renderCheckbox('environment_admin')}
      </Box>

      <Box className={classes.membersContainer}>
        {users.filter((e) => rolesSelected.includes(e.role)).length === 0 &&
          !loading && (
            <Typography
              variant="h4"
              style={{
                color: theme.palette.activeText.main
              }}
            >
              Nenhum usuário encontrado.
            </Typography>
          )}
        <Box className={classes.membersGrid} onScroll={getMoreItems}>
          {users
            .filter((e) => rolesSelected.includes(e.role))
            .map((enrollment) => (
              <MemberItem
                key={enrollment.id}
                enrollment={enrollment}
                updateRole={updateRole}
                setMembers={setUsers}
                canManage={canManage}
              />
            ))}
          {loading &&
            Array.from(new Array(8)).map((item, index) => (
              <Box key={index} className={classes.memberContainer}>
                <Box className={classes.memberAvatar}>
                  <Skeleton variant="circle" width={50} height={50} />
                </Box>
                <Box className={classes.memberInfo}>
                  <Skeleton
                    variant="rect"
                    height={14}
                    width="70%"
                    style={{ marginBottom: 5 }}
                  />
                  <Skeleton
                    variant="rect"
                    height={12}
                    width="40%"
                    style={{ marginBottom: 5 }}
                  />
                  <Skeleton variant="rect" height={12} width="55%" />
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Members;

Members.propTypes = {
  members: PropTypes.shape(),
  courseId: PropTypes.shape(),
  environmentId: PropTypes.shape(),
  spacesId: PropTypes.shape()
};

Members.defaultProps = {
  members: null,
  courseId: null,
  environmentId: null,
  spacesId: null
};
