import React from 'react';

import { ReactComponent as LectureIcon } from '~/assets/icons/lecture-icon.svg';
import settings from '~settings';

import { useStyles } from './styles';
import { IconButton, Box, ListItem, Avatar, useTheme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Parse from 'html-react-parser';
import PropTypes from 'prop-types';

const SearchListItem = ({ item, removeItem, wordSearched, goTo }) => {
  const classes = useStyles();

  const ItemHighlighted = () => {
    const substring = new RegExp(wordSearched, 'gi');
    const name = item.name;

    const itemHighlighted = name?.replace(substring, (match) => {
      return `<mark>${match}</mark>`;
    });

    const html = `
      <div class=${classes.listItemText} >
        <h1 class=${classes.itemText} >
          ${itemHighlighted}
        </h1>
      </div>
    `;

    return Parse(html);
  };

  return (
    <ListItem
      className={classes.container}
      component="button"
      onClick={() => goTo(item)}
    >
      <Box className={classes.infosWrapper}>
        {item.index !== 'lectures' ? (
          <Avatar
            className={classes.avatar}
            src={`${settings.baseUrl}${item.avatar}`}
          />
        ) : (
          <Box>
            <LectureIcon
              style={{
                width: '48px',
                height: '48px',
                fill: '#bdbdbd'
              }}
            />
          </Box>
        )}
        <Box
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-start',
            marginLeft: 14,
            overflow: 'hidden'
          }}
        >
          <ItemHighlighted />
        </Box>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            removeItem(item);
          }}
          className={classes.iconButton}
        >
          <Close className={classes.closeIcon} />
        </IconButton>
      </Box>
    </ListItem>
  );
};

SearchListItem.propTypes = {
  item: PropTypes.shape(),
  removeItem: PropTypes.func,
  toggleOpenDrawer: PropTypes.func,
  wordSearched: PropTypes.string,
  goTo: PropTypes.func
};

SearchListItem.defaultProps = {
  item: {},
  removeItem: null,
  toggleOpenDrawer: null,
  wordSearched: '',
  goTo: null
};

export default SearchListItem;
