import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as TropicalIslandImage } from '~/assets/images/tropical-island-cuate.svg';
import GenericFeedbackLayout from '~/components/molecules/GenericFeedbackLayout';
import AppBarPages from '~/components/templates/AppbarPages';
import Members from '~/components/templates/Members';
import i18n from '~/I18n';
import { createCertificate, getCertificate } from '~/services/Certificate';
import { findByID, getEnrollments, searchMembers } from '~/services/Space';
import { update as updateRole } from '~/services/user-space-association';

import Content from './Content';
import { SpacePageProvider } from './contexts/SpacePageContext';
import Discussion from './Discussion';
import Header from './Header';
import ManagerPopUp from './ManagerPopUp';
import PageLoading from './PageLoading';
import Sidebar from './Sidebar';
import { useStyles } from './styles';
import { Box, Button, useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';

export default function Space() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [section, setSection] = useState('content');
  const [space, setSpace] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const [generateCertificate, setGenerateCertificate] = useState(false);
  const [certificateID, setCertificateID] = useState(null);

  useEffect(() => {
    findByID(id)
      .then((space) => {
        setSpace(space);
      })
      .catch(console.log)
      .finally(() => setIsFetching(false));
  }, [id]);

  const renderSection = () => {
    if (section === 'content') {
      return <Content />;
    }

    if (section === 'discussion') {
      return <Discussion spaceID={id} canPublish={space.can_publish} />;
    }

    return (
      <Members
        structureID={id}
        getMembers={searchMembers}
        updateRole={updateRole}
      />
    );
  };

  useEffect(() => {
    if (space) {
      document.title = `${theme.title} | ${space.name}`;
      handleCertificateButton(space);
      setCertificateID(space.certificate_id);
    } else {
      document.title = theme.title;
    }
  }, [space]);

  const handleCertificateButton = (space) => {
    if (space.user_completion === undefined) return;
    const graduated =
      parseFloat(space.user_completion) >=
      parseFloat(space.minimum_rate_for_completion);

    if (
      (graduated && space.certificate_configuration_id) ||
      (graduated && space.certificate_id)
    ) {
      setGenerateCertificate(true);
    }
  };

  const downloadCertificate = async () => {
    try {
      let certificateIdToUse = certificateID;

      if (!certificateID) {
        const createResponse = await createCertificate(
          space.certificate_configuration_id
        );
        certificateIdToUse = createResponse.id;
        setCertificateID(certificateIdToUse);
      }

      const blob = await getCertificate(certificateIdToUse);

      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.target = '_blank';
      link.download = `certificado_${space.id}.pdf`;

      link.click();
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      enqueueSnackbar('Erro ao gerar certificado.', {
        variant: 'error'
      });
    }
  };

  return (
    <AppBarPages>
      <Box className={classes.root}>
        <Box className={classes.content}>
          {!isFetching && !space && (
            <GenericFeedbackLayout
              ImageIcon={TropicalIslandImage}
              title={'Não encontrado!'}
              description={`Não foi possível carregar a ${i18n.t(
                `StructuresName.Space.SingularUpper`
              )}`}
            />
          )}
          {isFetching && <PageLoading />}
          {space && (
            <SpacePageProvider
              section={section}
              setSection={setSection}
              space={space}
            >
              <Box display={{ xs: 'none', md: 'flex' }}>
                <Sidebar />
              </Box>
              <Box className={classes.infos}>
                <Header />

                {space.can_manage && <ManagerPopUp />}
                <Box className={classes.certificateInfo}>
                  {generateCertificate && (
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      className={classes.certificateButton}
                      onClick={downloadCertificate}
                    >
                      {`${i18n.t('GenerateCertificate')}`}
                    </Button>
                  )}
                </Box>

                <Box display={{ xs: 'flex', md: 'none' }}>
                  <Sidebar />
                </Box>

                <Box className={classes.infosContent}>
                  <>{renderSection()}</>
                </Box>
              </Box>
            </SpacePageProvider>
          )}
        </Box>
      </Box>
    </AppBarPages>
  );
}
