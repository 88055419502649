import base from './base';
const baseUrl = 'https://api.aedutec.redu.digital';
const apiUrl = `${baseUrl}/api`;
const clarityKey = 'lfjishq64e';

export default {
  ...base,
  baseUrl: baseUrl,
  apiUrl: apiUrl,
  searchAPIUrl: `https://search.aedutec.redu.digital`,
  AppKey: 'YFYfTtHfzWTblUVsg58hxqhZHMFXMm1_w9e5PDQKVMM',
  recaptchaKey: '6Le-l6IZAAAAAGaH2BCzGy6b80TVaASx_XMdnWx4',
  websocketUrl: 'wss://digital.admtec.org.br:44443',
  clarityKey
};
