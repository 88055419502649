import React, { useEffect, useState } from 'react';
import { MdFolderCopy } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { EnvironmentIcon } from '~/components/atoms/Icons';
import QuestionBankModal from '~/components/atoms/QuestionDatabaseModal';
import Show from '~/components/atoms/Show';
import { SearchSkeleton } from '~/components/Badges/Drawer/LeftDrawer/SearchSkeleton';
import i18n from '~/I18n';
import { generateAnalyticsToken } from '~/services/analytics';
import api from '~/services/api';
import history from '~/services/history';
import { searchAll } from '~/services/search';
import { Creators as AuthActions } from '~/store/ducks/auth';
import { setLocalStorage, getLocalStorage } from '~/utils/localStorage';
import settings from '~settings';

import SearchListItem from './SearchListItem';
import {
  SearchDrawer,
  SearchDrawerMenu,
  InputSearch,
  MenuItem,
  CloseButton,
  ExitButton,
  ContainerHeaderLeftDrawer,
  TitleHeaderContainerLeftDrawer,
  ButtonClearList,
  useStyles,
  SearchList,
  NothingFoundText
} from './styles';
import { List, Divider, Link as LinkMUI } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { LibraryBooks } from '@material-ui/icons';
import AssessmentIcon from '@material-ui/icons/Assessment';
import BookIcon from '@material-ui/icons/Book';
import HomeIcon from '@material-ui/icons/Home';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import MessageIcon from '@material-ui/icons/Message';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PersonIcon from '@material-ui/icons/Person';
import debounce from 'debounce';
import HeadsetMicIcon from 'mdi-react/HeadsetMicIcon';
import PropTypes from 'prop-types';

export default function LeftDrawer({
  children,
  toggleTheme,
  open,
  setOpen,
  marginTop,
  color
}) {
  const { signOut } = AuthActions;

  const [openQuestionDatabase, setOpenQuestionDatabase] = useState(false);
  const [searching, setSearching] = useState(false);
  const [wordSearched, setWordSearched] = useState('');
  const [listSearch, setListSearch] = useState([]);
  const { profile } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles({ theme });

  useEffect(() => {
    try {
      open && setListSearch(getLocalStorage('recentSearches') || []);
    } catch (e) {
      setListSearch([]);
    }
    return () => {
      setListSearch([]);
      setWordSearched('');
    };
  }, [open]);

  useEffect(() => {
    listSearch.length > 0 && saveRecentSearches(listSearch);
  }, [listSearch]);

  function removeItem(listItem) {
    if (listSearch && listSearch.length > 0) {
      const newList = listSearch.filter((item) => {
        if (item.id !== listItem.id) {
          return item;
        }
      });
      setListSearch(newList);
    }
  }

  const handleSearchInput = (event) => {
    if (debouncedSearch) debouncedSearch.clear();
    debouncedSearch(event.target.value);
  };

  const debouncedSearch = debounce((searchString) => {
    handleSearch(searchString);
  }, 500);

  async function handleSearch(text) {
    setWordSearched(text);
    if (searching) return;
    if (text.trim().length !== 0 && text != null) {
      setSearching(true);
      try {
        const { data } = await searchAll(text);
        const searchData = data.hits;
        setListSearch(searchData);
        saveRecentSearches(searchData);
      } catch (error) {
        setListSearch([]);
      } finally {
        setSearching(false);
      }
    }
  }

  const saveRecentSearches = (value) => {
    setLocalStorage('recentSearches', value);
  };

  function clearList() {
    saveRecentSearches([]);
    setListSearch([]);
  }

  function closeSearchDrawer() {
    setListSearch([]);
    setSearching(false);
    setOpen();
  }

  function goToItemPage(item) {
    const routeMap = {
      users: `/profile/${item.id}`,
      environments: `/dashboard/environments/${item.id}`,
      courses: `/dashboard/environments/${item.environment?.id}/courses/${item.id}`,
      spaces: `/dashboard/spaces/${item.id}`,
      lectures: `/dashboard/lecture/${item.id}`
    };

    history.push(routeMap[item.index]);
  }

  const ListItems = () => {
    return listSearch.map((item) => (
      <SearchListItem
        key={item.id}
        item={item}
        removeItem={removeItem}
        wordSearched={wordSearched}
        goTo={goToItemPage}
      />
    ));
  };

  return (
    <>
      <SearchDrawer
        handleSearch={handleSearch}
        open={open}
        list={listSearch}
        onClick={closeSearchDrawer}
      >
        <SearchDrawerMenu
          color={theme.palette.primary.main}
          onClick={(event) => event.stopPropagation()}
        >
          <CloseButton onClick={closeSearchDrawer} />
          <InputSearch
            placeholder={i18n.t('SearchSebraeEdu')}
            onChange={handleSearchInput}
          />
          <List>
            <Link exact to="/dashboard">
              <MenuItem
                title={i18n.t('HomePage')}
                icon={<HomeIcon className={classes.icon} />}
                onClick={setOpen}
              />
            </Link>
            <Link exact to="/dashboard/environments">
              <MenuItem
                title={i18n.t('MyEnvironments')}
                icon={
                  <EnvironmentIcon
                    color="white"
                    width={20}
                    style={{ marginRight: '8px' }}
                  />
                }
                onClick={setOpen}
              />
            </Link>
            <Link exact to={profile ? `/profile/${profile.id}` : '#'}>
              <MenuItem
                icon={<PersonIcon className={classes.icon} />}
                title={i18n.t('MenuItem.MyProfile')}
                onClick={setOpen}
              />
            </Link>
            <Link exact to="/messages">
              <MenuItem
                title={i18n.t('Messages')}
                icon={<MessageIcon className={classes.icon} />}
                onClick={setOpen}
              />
            </Link>
            <Show condition={profile?.can_mirror}>
              <Link exact to="/replicable_processes">
                <MenuItem
                  title={'Espelhamento'}
                  icon={<MdFolderCopy className={classes.icon} />}
                  onClick={setOpen}
                />
              </Link>
            </Show>
            <Show
              condition={profile?.laplace_access || profile?.inovaula_access}
            >
              <MenuItem
                title={i18n.t('QuestionDatabase')}
                icon={<BookIcon className={classes.icon} />}
                onClick={() => {
                  setOpen();
                  setOpenQuestionDatabase(true);
                }}
              />
            </Show>
            <Show condition={profile?.analytics_login_url}>
              <MenuItem
                icon={<AssessmentIcon className={classes.icon} />}
                title={i18n.t('Analytics')}
                onClick={async () => {
                  window.location.href = await generateAnalyticsToken();
                }}
              />
            </Show>
            <Link exact to="/help">
              <MenuItem
                title={i18n.t('ContactUs')}
                icon={<HeadsetMicIcon className={classes.icon} />}
                onClick={setOpen}
              />
            </Link>
            <MenuItem
              title={i18n.t('Manual')}
              icon={<MenuBookIcon className={classes.icon} />}
              onClick={() => {
                setOpen();
                window.open(settings.helpCenterUrl, '_blank');
              }}
            />
            <Link exact to="/privacy-policy-and-usage-terms">
              <MenuItem
                title={i18n.t('MenuItem.PrivacyPolicy')}
                icon={<LibraryBooks className={classes.icon} />}
                onClick={setOpen}
              />
            </Link>
          </List>
          <Divider />
          <LinkMUI
            href="/about"
            target="_blank"
            rel="noreferrer"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              marginTop: '16px'
            }}
          >
            <MenuItem
              title="Sobre a plataforma"
              rightIcon={
                <OpenInNewIcon
                  style={{
                    color: theme.palette.textAndBackground.main,
                    fontSize: '24px',
                    marginLeft: '8px'
                  }}
                />
              }
            />
          </LinkMUI>
          <ExitButton
            onClick={() => {
              dispatch(signOut());
            }}
          />
        </SearchDrawerMenu>
        <SearchDrawerMenu
          color="white"
          onClick={(event) => event.stopPropagation()}
        >
          <ContainerHeaderLeftDrawer>
            <TitleHeaderContainerLeftDrawer title={i18n.t('RecentSearches')} />
            <ButtonClearList
              onClick={() => {
                clearList();
              }}
            />
          </ContainerHeaderLeftDrawer>
          <SearchList>
            {searching ? <SearchSkeleton /> : <ListItems />}
            {!searching && listSearch && listSearch.length === 0 && (
              <NothingFoundText />
            )}
          </SearchList>
        </SearchDrawerMenu>
      </SearchDrawer>
      <QuestionBankModal
        open={openQuestionDatabase}
        setOpen={setOpenQuestionDatabase}
      />
    </>
  );
}

LeftDrawer.propTypes = {
  children: PropTypes.shape(),
  toggleTheme: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func
};

LeftDrawer.defaultProps = {
  children: {},
  toggleTheme: null,
  open: false,
  setOpen: null
};
