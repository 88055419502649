import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import api from '~/services/api';
import history from '~/services/history';
import { searchAPI } from '~/services/search';
import { Creators as AuthActions } from '~/store/ducks/auth';
import { Creators as UserActions } from '~/store/ducks/user';
import { loginValidations } from '~/utils/LoginValidation/yup';

import { signIn } from './fetch';
import LoginFail from './LoginFail';
import LoginForm from './LoginForm';
import SubmitButton from './SubmitButton';
import { yupResolver } from '@hookform/resolvers/yup';

export default function ReduLogin() {
  const dispatch = useDispatch();
  const callbackUrl = new URLSearchParams(useLocation().search).get(
    'callbackUrl'
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const { signInSuccess } = AuthActions;
  const { getProfileRequest } = UserActions;

  const defaultValues = {
    login: '',
    password: '',
    recaptcha: false,
    remember: true
  };

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(loginValidations)
  });

  const onSubmit = (values) => {
    setIsSubmitting(true);
    signIn(values.login, values.password, values.remember)
      .then(handleLoginSuccess)
      .catch(handleLoginFailure);
  };

  const handleLoginSuccess = (data) => {
    api.defaults.headers.Authorization = data.token;
    searchAPI.defaults.headers.Authorization = `Bearer ${data.token}`;

    dispatch(signInSuccess(data.token, data.remember));
    dispatch(getProfileRequest());

    if (callbackUrl) {
      history.push(callbackUrl);
    } else {
      history.push('/dashboard');
    }

    setIsSubmitting(false);
  };

  const handleLoginFailure = (error) => {
    setError(error.response.status);
    setIsSubmitting(false);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {error ? <LoginFail error={error} /> : null}

        <LoginForm />

        <div style={{ marginTop: '12px' }}>
          <SubmitButton isSubmitting={isSubmitting} />
        </div>
      </form>
    </FormProvider>
  );
}
