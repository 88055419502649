import { buildUrlWithParams } from '~/utils/buildUrlWithParams';
import settings from '~settings';

import axios from 'axios';

export const searchAPI = axios.create({
  baseURL: settings.searchAPIUrl,
  headers: {
    Accept: 'application/json'
  }
});

export const searchAll = async (query) => {
  const response = await searchAPI.get(`v1?q=${query}`);
  return response;
};

export const searchUsers = async (query) => {
  const response = await searchAPI.get(`v1/users?q=${query}`);
  return response;
};

export const searchEnvironmentUsers = async (
  environment_id,
  query,
  page,
  perPage
) => {
  const response = await searchAPI.get(
    buildUrlWithParams(`v1/environments/${environment_id}/users`, {
      q: query,
      page,
      per_page: perPage
    })
  );
  return response;
};
export const searchCourseUsers = async (course_id, query, page, perPage) => {
  const response = await searchAPI.get(
    buildUrlWithParams(`v1/courses/${course_id}/users`, {
      q: query,
      page,
      per_page: perPage
    })
  );
  return response;
};
export const searchSpaceUsers = async (space_id, query, page, perPage) => {
  const response = await searchAPI.get(
    buildUrlWithParams(`v1/spaces/${space_id}/users`, {
      q: query,
      page,
      per_page: perPage
    })
  );
  return response;
};

export default {
  searchAll,
  searchUsers,
  searchEnvironmentUsers,
  searchCourseUsers,
  searchSpaceUsers
};
