/* eslint-disable react/display-name */
import React from 'react';

import Text from '~/components/atoms/Text';
import i18n from '~/I18n';

import { useStyles } from '../styles';
import * as S from './styles';
import { Avatar, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

const MemberItem = ({ enrollment }) => {
  const theme = useTheme();
  const classes = useStyles({ theme: theme });

  const roleDict = {
    admin: i18n.t('Admin'),
    moderator: i18n.t('Moderator'),
    member: i18n.t('Student'),
    environment_admin: i18n.t('Environment_admin'),
    course_admin: i18n.t('Course_admin'),
    teacher: i18n.t('Teacher'),
    tutor: i18n.t('Tutor'),
    student: i18n.t('Student')
  };

  return (
    <S.MemberWrapper bgColor={theme.palette.primary.main}>
      <S.AvatarWrapper>
        <Avatar
          src={enrollment?.user.avatar}
          style={{
            width: '50px',
            height: '50px'
          }}
        />
      </S.AvatarWrapper>
      <S.LinkProfile to={`/profile/${enrollment?.user.id}`}>
        <S.WrapperUserData className={classes.memberInfo}>
          <Text
            color="accent"
            variant="text"
            style={{
              textTransform: 'capitalize',
              fontWeight: 500,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%'
            }}
          >
            {enrollment.user.name}
          </Text>
          <Text variant="mediumText" color="gray">{`${
            roleDict[enrollment.role]
          }(a)`}</Text>
        </S.WrapperUserData>
      </S.LinkProfile>
    </S.MemberWrapper>
  );
};

export default MemberItem;

MemberItem.propTypes = {
  enrollment: PropTypes.shape()
};

MemberItem.defaultProps = {
  enrollment: {}
};
