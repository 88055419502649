import base from './base';
const baseUrl = 'https://api.avamecinterativo.mec.gov.br';
const apiUrl = `${baseUrl}/api`;
const clarityKey = 'lfjtot37zi';

export default {
  ...base,
  baseUrl: baseUrl,
  apiUrl: apiUrl,
  searchAPIUrl: `https://search.avamecinterativo.mec.gov.br`,
  AppKey: 'dn2YYVMyYDda0kxw2ggbhKvE18i2WTg4Azvd472r',
  recaptchaKey: '6Le-l6IZAAAAAGaH2BCzGy6b80TVaASx_XMdnWx4',
  websocketUrl: 'wss://api.avamecinterativo.mec.gov.br:44443',
  clarityKey
};
