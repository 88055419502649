import base from './base';
const baseUrl = 'https://api.conectamaisedu.com.br';
const apiUrl = `${baseUrl}/api`;
const clarityKey = 'lfkaj8lvx5';

export default {
  ...base,
  baseUrl: baseUrl,
  apiUrl: apiUrl,
  searchAPIUrl: `https://search.conectamaisedu.com.br`,
  AppKey: 'iHomEd94SFmDeZq1Vk68xvYIABOxeJL3WdcigESAxE2g',
  recaptchaKey: '6Le-l6IZAAAAAGaH2BCzGy6b80TVaASx_XMdnWx4',
  websocketUrl: 'wss://api.conectamaisedu.com.br:44443',
  clarityKey
};
