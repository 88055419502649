/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import api from '~/services/api';
import history from '~/services/history';
import { searchAPI } from '~/services/search';
import { processSebraeAuth } from '~/services/sebraeID';
import { Creators as AuthActions } from '~/store/ducks/auth';
import { Creators as UserActions } from '~/store/ducks/user';
import settings from '~settings';

import ErrorAlert from './ErrorAlert';
import { sendEnrollmentRequest } from './fetch';
import LoadingModal from './LoadingModal';
import base32 from 'base32';

export default function Callback({ code, courseToken }) {
  const message = 'Falha ao tentar entrar com o Sebrae ID';

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const { signInSuccess } = AuthActions;
  const { getProfileRequest } = UserActions;

  const handleSuccess = async (userData) => {
    const { sa_token: token, id } = userData;
    api.defaults.headers.Authorization = token;
    searchAPI.defaults.headers.Authorization = `Bearer ${token}`;
    api.defaults.headers.SebraeKey = settings.sebraeKey;

    dispatch(signInSuccess(token, true));
    dispatch(getProfileRequest());

    if (courseToken) {
      const courseId = await base32.decode(courseToken);

      try {
        const { course_id, course } = await sendEnrollmentRequest(id, courseId);
        history.push(
          `/dashboard/environments/${course.environment_id}/courses/${course_id}`
        );
        setLoading(false);
      } catch (error) {
        history.push('/dashboard?se=true');
        setLoading(false);
      }
    } else {
      history.push('/dashboard');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (code)
      processSebraeAuth(code, courseToken)
        .then(handleSuccess)
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setOpen(true);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, courseToken]);

  return loading ? (
    <LoadingModal loading={loading} />
  ) : (
    <ErrorAlert message={message} open={open} setOpen={setOpen} />
  );
}
