import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Avatar from '~/components/atoms/Avatar';
import { searchUsers } from '~/services/search';
import { searchContactOn } from '~/socket/contact-events';

import { useStyles } from './styles';
import { Box, TextField, useTheme } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { Alert, Autocomplete } from '@material-ui/lab';
import { debounce } from 'debounce';
import PropTypes from 'prop-types';

export const ConversationContactSearch = ({ profile }) => {
  const [contacts, setContacts] = useState([]);
  const theme = useTheme();
  const classes = useStyles({ theme });
  const history = useHistory();

  useEffect(() => {
    if (profile) {
      searchContactOn(profile?.id, (contacts) => {
        setContacts(contacts);
      });
    }
  }, [profile]);

  const handleChange = (event) => {
    if (debouncedSearch) debouncedSearch.clear();
    debouncedSearch(event.target.value);
  };

  const debouncedSearch = debounce((searchString) => {
    handleSearch(searchString);
  }, 300);

  const handleSearch = async (searchString) => {
    try {
      const { data } = await searchUsers(searchString);
      console.log(data.hits);
      setContacts(data.hits.filter((contact) => contact.id !== profile.id));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box>
      <Autocomplete
        options={contacts}
        classes={{
          option: classes.option
        }}
        autoHighlight
        getOptionLabel={(option) => `${option.name}`}
        noOptionsText="Sem contatos"
        onChange={(event, newValue) => {
          if (newValue?.id) {
            history.push(`/messages/${newValue.id}`);
          }
        }}
        onInputChange={handleChange}
        renderOption={(option) => {
          return (
            <React.Fragment>
              <Box
                style={{ marginRight: '10px' }}
                onClick={() => Alert(option.name)}
              >
                <Avatar
                  size={30}
                  profile={{
                    id: option?.id,
                    first_name: option?.name,
                    thumbnails: option?.avatar
                  }}
                />
              </Box>
              {option.name}
            </React.Fragment>
          );
        }}
        style={{ width: '100%' }}
        renderInput={(params) => (
          <TextField
            ref={params.InputProps.ref}
            className=""
            {...params}
            variant="outlined"
            placeholder="Buscar contato"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ fontSize: '18' }}>
                  <SearchIcon fontSize="large" />
                </InputAdornment>
              ),
              style: {
                fontSize: '16px'
              }
            }}
          />
        )}
      />
    </Box>
  );
};

ConversationContactSearch.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
};
