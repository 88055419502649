import api from '~/services/api';
import { searchEnvironmentUsers } from '~/services/search';
import { buildUrlWithParams } from '~/utils/buildUrlWithParams';

const baseURL = '/api/environments';

const defaultPerPage = 20;

// Function to fetch paginated environments with filters
export const getAll = async (page = 1, filters = {}) => {
  const {
    state = '',
    regionalGroup = '',
    city = '',
    ord = '',
    direction = '',
    name_like = ''
  } = filters;
  const { data } = await api.get(
    buildUrlWithParams(baseURL, {
      state: state,
      regional_group: regionalGroup,
      city: city,
      order: ord,
      sort: direction,
      name_like: name_like,
      page,
      per_page: defaultPerPage
    })
  );
  return data;
};

// Fetches available filters for environments based on optional filter parameters.

export const getFilters = async (filters = {}) => {
  const { state = '', regionalGroup = '', city = '' } = filters;

  const { data } = await api.get(
    buildUrlWithParams(`${baseURL}/filter_options`, {
      state,
      regional_group: regionalGroup,
      city,
      per_page: defaultPerPage
    })
  );

  return data;
};

// Retrieves enrollments for a specific environment with pagination.
export const getEnrollments = async (environmentID, page, perPage = 18) => {
  const { data } = await api.get(
    buildUrlWithParams(
      `${baseURL}/${environmentID}/user_environment_associations`,
      { page, per_page: perPage }
    )
  );
  return data;
};

// Fetches the enrollment link for a specific environment.
export const getEnrollmentLink = async (environmentID) => {
  const { data } = await api.get(
    `${baseURL}/${environmentID}/enrollment_links`
  );
  return data;
};

// Creates a new enrollment link for an environment.
export const createEnrollmentLink = async (environmentID, body) => {
  const { data } = await api.post(
    `${baseURL}/${environmentID}/enrollment_links`,
    {
      enrollment_link: body
    }
  );
  return data;
};

// Retrieves details of a specific environment by its ID.
export const findByID = async (environmentID) => {
  const { data } = await api.get(`${baseURL}/${environmentID}`);
  return data;
};

// Retrieves details of a specific environment by its name.
export const findByName = async (name) => {
  const { data } = await api.get(`${baseURL}/find_by_name?name=${name}`);
  return data;
};

// Searches for members within an environment using a search string and pagination.
export const searchMembers = async (
  environmentID,
  searchString,
  page,
  perPage = 18
) => {
  const { data } = await searchEnvironmentUsers(
    environmentID,
    searchString,
    page,
    perPage
  );
  return data.hits;
};

// Deletes specified members from an environment.
export const deleteMembers = async (environmentID, membersID) => {
  await api.delete(
    `${baseURL}/${environmentID}/user_environment_associations`,
    { data: { collection_ids: membersID } }
  );
};

// Imports members to an environment from a provided file.
export const importMembersFromFile = async (environmentID, fileData) => {
  await api.post(
    `${baseURL}/${environmentID}/import_data/import_members`,
    fileData,
    {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${fileData._boundary}`
      }
    }
  );
};

// Removes members from an environment based on data from a provided file.
export const removeMembersFromFile = async (environmentID, fileData) => {
  await api.post(
    `${baseURL}/${environmentID}/import_data/remove_members`,
    fileData,
    {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${fileData._boundary}`
      }
    }
  );
};

// Checks if a user is associated with a specific environment.
export const checkAssociation = async (environmentID, userID) => {
  const { data } = await api.get(
    `/api/users/${userID}/environments/${environmentID}/user_environment_association`
  );
  return data;
};

// Updates roles of specific members within an environment.
export const updateRoles = async (environmentID, membersID, role) => {
  await api.put(`${baseURL}/${environmentID}/user_environment_associations`, {
    collection_ids: membersID,
    role
  });
};

export const getSubjects = async (environmentID, page = 1, perPage = 10) => {
  const { data } = await api.get(
    `${baseURL}/${environmentID}/subjects?page=${page}&per_page=${perPage}`
  );
  return data;
};

export const createUser = async (environmentID, body) => {
  const { data } = await api.post(`${baseURL}/${environmentID}/users`, body);
  return data;
};

export const editUser = async (environmentID, userID, userData) => {
  const { data } = await api.put(
    `${baseURL}/${environmentID}/users/${userID}/update_by_environment_admin`,
    {
      user: userData
    }
  );
  return data;
};

export const deleteEnvironment = async (environmentID) => {
  const { data } = await api.delete(`${baseURL}/${environmentID}`);
  return data;
};

export default {
  getAll,
  getFilters,
  getEnrollments,
  getEnrollmentLink,
  createEnrollmentLink,
  findByID,
  searchMembers,
  deleteMembers,
  importMembersFromFile,
  removeMembersFromFile,
  updateRoles,
  checkAssociation,
  createUser,
  deleteEnvironment
};
